.topbar {
  background-color: #ffff;
}
.btnBlack {
  text-decoration: none;
  background-color: #fff;
  color: #333;
  padding: 0.2rem 0.6rem;
  font-size: 0.8rem;
  border-radius: 0.25rem;
  border: #000;
  border: 1px solid #666;
  border-width: 1px 2px 2px 1px;
  margin-right: 0.3rem;
}
.btnBlack:hover {
  background: #000;
  color: #fff;
}

.btnBlue {
  text-decoration: none;
  background-color: #555;
  color: #fff;
  padding: 0.2rem 0.6rem;
  font-size: 0.8rem;
  border-radius: 0.25rem;
  border: solid #333 1px;
  border-right: solid #333 2px;
  border-bottom: solid #333 2px;
  margin-left: 0.5rem;
}

.btnBlue:hover {
  background: #000;
  color: #fff;
}
.btnNotification {
  background-color: transparent;
  padding: 0.5rem;
  border: none;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btnNotification:hover {
  background-color: transparent;
  color: #000;
}

.notificationIcon {
  width: 24px;
  height: 24px;
  color: #333;
}

.unreadCount {
  background-color: red;
  color: white;
  font-size: 0.8rem;
  width: 1.2rem;
  height: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  top: 8px;
  right: 8px;
  font-weight: bold;
  line-height: 1rem; /* Ensures proper vertical alignment */
}

.unreadCount .plus {
  font-size: 0.8rem;
  position: relative;
  top: -3px;
  margin-left: -2px;
}

.tooltip {
  position: absolute;
  right: -5rem;
  top: 3.2rem;
  background-color: #fff;
  color: #333;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  white-space: nowrap;
  z-index: 9999;
  width: 20rem;
  max-width: 25rem;
  max-height: 30rem;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.tooltip::-webkit-scrollbar {
  display: none;
}

.lmd {
  font-size: 0.75rem;
  color: #666;
}

@media (max-width: 576px) {
  .lmd {
    font-size: 0.7rem;
    color: #666;
  }
  @keyframes slideIn {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    25% {
      transform: translateY(-50%);
      opacity: 0.5;
    }
    50% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slideOut {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(-100%);
      opacity: 0;
    }
  }

  .slide-in {
    animation: slideIn 3s ease-in-out;
  }

  .slide-out {
    animation: slideOut 1s forwards;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(-20px);
    }
  }

  .fade-in {
    animation: fadeIn 0.8s forwards;
  }

  .fade-out {
    animation: fadeOut 0.5s forwards;
  }
}



@media (max-width: 576px) {
  .tooltip {
    top: 3.2rem;
    right: -5.7rem;
  }
}
