@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
:root {
  --default-font: "Inter", system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --heading-font: "Inter", sans-serif;
  --nav-font: "Inter", sans-serif;
}

:root {
  --background-color: #ffffff; /* Background color for the entire website, including individual sections */
  --default-color: #333; /* Default color used for the majority of the text content across the entire website */
  --default-color-1: #fff;
  --heading-color: #d32f2f; /* Color for headings, subheadings and title throughout the website */
  --heading-color-1: #000;
  --heading-color-2: #434040;
  --accent-color: #d32f2f; /* Accent color that represents your brand on the website. It's used for buttons, links, and other elements that need to stand out */
  --accent-color-1: #333;
  --surface-color: #ffffff; /* The surface color is used as a background of boxed elements within sections, such as cards, icon boxes, or other elements that require a visual separation from the global background. */
  --contrast-color: #ffffff; /* Contrast color for text, ensuring readability against backgrounds of accent, heading, or default colors. */
}

:root {
  --nav-color: #2c4964; /* The default color of the main navmenu links */
  --nav-hover-color: #d32f2f; /* Applied to main navmenu links when they are hovered over or active */
  --nav-mobile-background-color: #ffffff; /* Used as the background color for mobile navigation menu */
  --nav-dropdown-background-color: #ffffff; /* Used as the background color for dropdown items that appear when hovering over primary navigation items */
  --nav-dropdown-color: #2c4964; /* Used for navigation links of the dropdown items in the navigation menu. */
  --nav-dropdown-hover-color: #d32f2f; /* Similar to --nav-hover-color, this color is applied to dropdown navigation links when they are hovered over. */
}

.light-background {
  --background-color: #333;
  --surface-color: #ffffff;
}

.dark-background {
  --background-color: #060606;
  --default-color: #ffffff;
  --heading-color: #ffffff;
  --surface-color: #252525;
  --contrast-color: #ffffff;
}

/* Smooth scroll */
:root {
  scroll-behavior: smooth;
}

/*--------------------------------------------------------------
# General Styling & Shared Classes
--------------------------------------------------------------*/
body {
  color: #333;
  background-color: #fff !important;
  font-family: var(--default-font) !important;
}

a {
  color: var(--accent-color);
  text-decoration: none !important;
  transition: 0.3s;
}

a:hover {
  color: color-mix(in srgb, var(--accent-color), transparent 25%);
  text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color) !important ;
  font-family: var(--heading-font);
}

/* Pulsating Play Button
------------------------------*/
.pulsating-play-btn {
  width: 94px;
  height: 94px;
  background: radial-gradient(
    var(--accent-color) 50%,
    color-mix(in srgb, var(--accent-color), transparent 75%) 52%
  );
  border-radius: 50%;
  display: block;
  position: relative;
  overflow: hidden;
}

.pulsating-play-btn:before {
  content: "";
  position: absolute;
  width: 120px;
  height: 120px;
  animation-delay: 0s;
  animation: pulsate-play-btn 2s;
  animation-direction: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid color-mix(in srgb, var(--accent-color), transparent 30%);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}

.pulsating-play-btn:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.pulsating-play-btn:hover:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 200;
  animation: none;
  border-radius: 0;
}

.pulsating-play-btn:hover:after {
  border-left: 15px solid var(--accent-color);
  transform: scale(20);
}

@keyframes pulsate-play-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

/* PHP Email Form Messages
------------------------------*/
.php-email-form .error-message {
  display: none;
  background: var(--accent-color);
  color: #ffffff;
  text-align: left;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.php-email-form .sent-message {
  display: none;
  color: #ffffff;
  background: #059652;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.php-email-form .loading {
  display: none;
  background: var(--surface-color);
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
}

.php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid var(--accent-color);
  border-top-color: var(--surface-color);
  animation: php-email-form-loading 1s linear infinite;
}

@keyframes php-email-form-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Global Header
--------------------------------------------------------------*/
.header {
  color: var(--default-color);
  transition: all 0.5s;
  z-index: 997;
  background-color: var(--background-color);
}

.header .topbar {
  background-color: #f2f2f2;
  height: 40px;
  padding: 0;
  font-size: 14px;
  color: #333;
  transition: all 0.5s;
  width: 100%;
}

.header .topbar .contact-info i {
  font-style: normal;
  color: #333;
}

.header .topbar .contact-info i a,
.header .topbar .contact-info i span {
  padding-left: 5px;
  color: #333;
  text-decoration: none;
}

@media (max-width: 575px) {
  .header .topbar .contact-info i a,
  .header .topbar .contact-info i span {
    font-size: 13px;
  }
}

.header .topbar .contact-info i a {
  line-height: 0;
  transition: 0.3s;
}

.header .topbar .contact-info i a:hover {
  color: #000;
  text-decoration: underline;
}

.header .topbar .social-links a {
  color: #333;
  line-height: 0;
  transition: 0.3s;
  margin-left: 20px;
  font-size: 18px;
}

.header .topbar .social-links a:hover {
  color: #000;
}

.header .branding {
  padding: 20px 0;
  width: 100%;
}
.pd-nav {
  padding: 0px 0px 0px 0px;
}

@media (max-width: 969px) {
  .header .logo img {
    margin-left: 8px;
  }
}
.bgRedHeader {
  /* background-color: #d32f2f !important; */
  background: rgb(158, 21, 21) !important;
  background: linear-gradient(
    30deg,
    rgba(158, 21, 21, 1) 0%,
    rgba(211, 47, 47, 1) 40%,
    rgba(250, 82, 82, 1) 100%
  ) !important;
}

.freePR a {
  font-size: 14px;

  padding: 13px 0px 13px 30px;
  color: #fff;
  font-weight: 500;
}

.freePR a:hover {
  font-size: 14px;
  padding: 13px 0px 13px 30px;
  color: #e9cfcf;
  font-weight: 500;
}
.navmenu a {
  font-size: 14px !important;
  color: #fff !important;
}

.dropdownItem > a {
  color: #000 !important;
  font-weight: 400 !important;
}
.dropdownItem:hover > a {
  color: #fff !important;
}
.header .logo {
  line-height: 1;
}

.header .logo h1 {
  font-size: 30px;
  margin: 0;
  font-weight: 700;
  color: var(--heading-color);
}

.header .cta-btn,
.header .cta-btn:focus {
  color: var(--contrast-color);
  background: var(--accent-color);
  font-size: 14px;
  padding: 8px 25px;
  margin: 0 0 0 30px;
  border-radius: 50px;
  transition: 0.3s;
  text-decoration: none;
  width: 90px;
  text-align: center;
}

.header .cta-btn:hover,
.header .cta-btn:focus:hover {
  color: var(--contrast-color);
  background: color-mix(in srgb, var(--accent-color), transparent 15%);
}
@media (min-width: 1200px) {
  .header .logo {
    width: 80%;
  }
}
@media (max-width: 1200px) {
  .header .logo {
    order: 1;
    width: 80%;
  }

  .header .cta-btn {
    order: 2;
    margin: 0 15px 0 0;
    padding: 6px 15px;
  }

  .header .navmenu {
    order: 3;
  }
}

.scrolled .header {
  box-shadow: 0px 0 18px rgba(0, 0, 0, 0.1);
}

.scrolled .header .topbar {
  height: 0;
  visibility: hidden;
  overflow: hidden;
}
.signin-signup-title {
  font-size: 22px;
  margin-bottom: 16px;
}

.signup-desc {
  font-size: 14px;
  margin: 1.2rem 0 1.8rem 0;
}

.signin-with-google {
  margin-top: -40px;
  margin-bottom: 15px;
}
.line-with-or {
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: bold;
  color: #999;
  margin-bottom: 15px;
}

.line-with-or::before,
.line-with-or::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #999;
  margin: 0 10px;
}

.signin-btn,
.signin-btn:focus {
  color: var(--contrast-color);
  background: var(--accent-color);
  font-size: 18px;
  font-weight: 400;
  padding: 10px 20px;
  /* margin-bottom: 16px; */
  border: 0px;
  border-radius: 0.25rem;
  transition: 0.3s;
  text-decoration: none;
  width: 100%;
  text-align: center;
}

.signin-btn:hover,
.signin-btn:focus:hover {
  color: var(--contrast-color);
  background: color-mix(in srgb, var(--accent-color), transparent 15%);
}

.common-btn,
.common-btn:focus {
  color: var(--contrast-color) !important;
  background: var(--accent-color);
  border: 0px;
  padding: 10px 20px;
  border-radius: 3px;
  font-weight: 600;
}

.common-btn:hover,
.common-btn:focus:hover {
  color: var(--contrast-color);
  background: color-mix(in srgb, var(--accent-color), transparent 15%);
}

.cancel-btn,
.cancel-btn:focus {
  color: #2b1f1fe0;
  background: #cbcbcb;
  border: 0px;
  padding: 10px 20px;
  border-radius: 3px;
  font-weight: 600;
}

.term-policy-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
}

.termAndPolicy {
  color: #007bff;
  padding: 0px 3px;
}

.googleButton {
  border-radius: 0.25rem;
  background-color: transparent;
  padding: 8px;
  margin: 50px 0px;
  border: 1px solid #dbdfe6;
  font-size: 14px;
}
.googleButton:hover {
  background-color: #fbf5f4;
}
.googleImage {
  height: 1.3rem;
  float: left;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Desktop Navigation */

@media (min-width: 1200px) {
  .navmenu {
    padding: 0;
  }

  .navmenu ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }

  .navmenu li {
    position: relative;
  }

  .navmenu > ul > li {
    white-space: nowrap;
    padding: 10px 25px;
  }

  .navmenu > ul > li:last-child {
    padding-right: 0;
  }

  .navmenu a,
  .navmenu a:focus {
    text-decoration: none;
    color: var(--nav-color);
    font-size: 16px;
    padding: 0 2px;
    font-family: var(--nav-font);
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    transition: 0.3s;
    position: relative;
  }

  .navmenu a i,
  .navmenu a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
    transition: 0.3s;
  }

  .navmenu > ul > li > a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0px;
    left: 0;
    background-color: inherit;
    visibility: hidden;
    width: 0px;
    transition: all 0.3s ease-in-out 0s;
  }

  .navmenu a:hover:before,
  .navmenu li:hover > a:before,
  .navmenu .active:before {
    visibility: visible;
    width: 100%;
  }

  .navmenu li:hover > a,
  .navmenu .active,
  .navmenu .active:focus {
    color: var(--nav-hover-color);
  }

  .navmenu .dropdown ul {
    margin: 0;
    padding: 10px 0;
    background: var(--nav-dropdown-background-color);
    display: block;
    position: absolute;
    visibility: hidden;
    left: 14px;
    top: 130%;
    opacity: 0;
    transition: 0.3s;
    border-radius: 4px;
    z-index: 99;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  }

  .navmenu .dropdown ul li {
    min-width: 200px;
  }

  .navmenu .dropdown ul a {
    padding: 10px 20px;
    font-size: 15px;
    text-transform: none;
    color: var(--nav-dropdown-color);
  }

  .navmenu .dropdown ul a i {
    font-size: 12px;
  }

  .navmenu .dropdown ul a:hover,
  .navmenu .dropdown ul .active:hover,
  .navmenu .dropdown ul li:hover > a {
    color: var(--nav-dropdown-hover-color);
  }

  .navmenu .dropdown:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }

  .navmenu .dropdown .dropdown ul {
    top: 0;
    left: -90%;
    visibility: hidden;
  }

  .navmenu .dropdown .dropdown:hover > ul {
    opacity: 1;
    top: 0;
    left: -100%;
    visibility: visible;
  }
}

.buttonClassPrimary {
  font-size: 14px;
  font-weight: 550;
  text-transform: uppercase;
  border: 0px;
  padding: 6px 25px 6px 25px;
  color: #fff;
  border-color: #000;
  background-color: #333;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.buttonClassPrimary:hover {
  font-size: 14px;
  font-weight: 550;
  text-transform: uppercase;
  border: 0px;
  padding: 6px 25px 6px 25px;
  color: #fff;
  border-color: #444;
  background-color: #000;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

/* Mobile Navigation */
@media (max-width: 1199px) {
  .mobile-nav-toggle {
    color: var(--nav-color) !important;
    font-size: 28px;
    /* line-height: 0; */
    margin: auto;
    margin-right: 10px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .navmenu {
    padding: 0;
    z-index: 9997;
  }

  .navmenu ul {
    display: none;
    list-style: none;
    position: absolute;
    inset: 60px 20px 20px 20px;
    padding: 10px 0;
    margin: 0;
    border-radius: 6px;
    background-color: var(--nav-mobile-background-color);
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
    box-shadow: none;
    overflow-y: auto;
    transition: 0.3s;
    z-index: 9998;
  }

  .navmenu a,
  .navmenu a:focus {
    text-decoration: none;
    color: var(--nav-dropdown-color);
    padding: 10px 20px;
    font-family: var(--nav-font);
    font-size: 17px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    transition: 0.3s;
  }

  .navmenu a i,
  .navmenu a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: 0.3s;
    background-color: color-mix(in srgb, var(--accent-color), transparent 90%);
  }

  .navmenu a i:hover,
  .navmenu a:focus i:hover {
    background-color: var(--accent-color);
    color: var(--contrast-color);
  }

  .navmenu a:hover,
  .navmenu .active,
  .navmenu .active:focus {
    color: var(--nav-dropdown-hover-color);
  }

  .navmenu .active i,
  .navmenu .active:focus i {
    background-color: var(--accent-color);
    color: var(--contrast-color);
    transform: rotate(180deg);
  }

  .navmenu .dropdown ul {
    position: static;
    display: none;
    z-index: 99;
    padding: 10px 0;
    margin: 10px 20px;
    background-color: var(--nav-dropdown-background-color);
    transition: all 0.5s ease-in-out;
  }

  .navmenu .dropdown ul ul {
    background-color: rgba(33, 37, 41, 0.1);
  }

  .navmenu .dropdown > .dropdown-active {
    display: block;
    background-color: rgba(33, 37, 41, 0.03);
  }

  .mobile-nav-active {
    overflow: hidden;
  }

  .mobile-nav-active .mobile-nav-toggle {
    color: #fff;
    position: absolute !important;
    font-size: 32px;
    top: 65px;
    right: 22px;
    margin-right: 0;
    z-index: 9999;
  }

  .mobile-nav-active .navmenu {
    position: fixed;
    overflow: hidden;
    inset: 0;
    background: rgba(33, 37, 41, 0.8);
    transition: 0.3s;
  }

  .mobile-nav-active .navmenu > ul {
    display: block;
  }
}

.register-signin-heading {
  text-decoration: none;
  color: var(--heading-color);
}

.form-label {
  margin-top: 0.3rem !important;
  margin-bottom: 0.2rem !important;
}

.form-control {
  /* height: 40px !important; */
  padding: 3px 6px 5px 6px !important;
  border-radius: 0.25rem !important;
  border: 2px solid #ced4da !important;
}
.form-control:focus {
  box-shadow: none !important;
  border: 1px solid var(--accent-color) !important;
  outline: none !important;
}

.css-t3ipsp-control:hover,
.css-t3ipsp-control:hover:focus {
  border: 1px solid red !important;
  box-shadow: none !important;
}
/*--------------------------------------------------------------
# Global Footer
--------------------------------------------------------------*/
.footer {
  color: var(--default-color-1);
  background-color: var(--accent-color-1) !important;
  border-top: 1px solid color-mix(in srgb, var(--accent-color), transparent 75%);
  font-size: 14px;
  position: relative;
}

.footer .footer-top {
  padding-top: 50px;
}

.footer .footer-about .logo {
  line-height: 1;
  margin-bottom: 25px;
}

.footer .footer-about .logo img {
  /* max-height: 40px; */
  margin-right: 6px;
}

.footer .footer-about .logo span {
  color: var(--heading-color);
  font-family: var(--heading-font);
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 1px;
}

.footer .footer-about p {
  font-size: 14px;
  font-family: var(--heading-font);
}

.footer .social-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  /* border: 1px solid color-mix(in srgb, var(--default-color), transparent 50%); */
  border: 1px solid color-mix(in srgb, var(--default-color-1), transparent 50%);
  font-size: 16px;
  /* color: color-mix(in srgb, var(--default-color), transparent 20%); */
  color: color-mix(in srgb, var(--default-color-1), transparent 20%);
  margin-right: 10px;
  transition: 0.3s;
}

.footer .social-links a:hover {
  color: var(--accent-color);
  border-color: var(--accent-color);
}

.footer h4 {
  font-size: 16px;
  font-weight: bold;
  position: relative;
  padding-bottom: 12px;
  color: var(--contrast-color);
}

.footer .footer-links {
  margin-bottom: 30px;
}

.footer .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .footer-links ul i {
  padding-right: 2px;
  font-size: 12px;
  line-height: 0;
}

.footer .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.footer .footer-links ul li:first-child {
  padding-top: 0;
}

.footer .footer-links ul a {
  /* color: color-mix(in srgb, var(--default-color), transparent 30%); */
  text-decoration: none;
  color: var(--default-color-1);
  display: inline-block;
  line-height: 1.5;
}

.footer .footer-links ul a:hover {
  color: var(--accent-color);
}

.footer .footer-contact p {
  margin-bottom: 5px;
  color: var(--default-color-1) !important;
}
.footer .footer-contact p a {
  color: var(--default-color-1);
  text-decoration: none;
}
.footer .footer-contact p a:hover {
  color: var(--accent-color);
}

@media (max-width: 768px) {
  .footer-about {
    margin-bottom: 20px;
  }

  .navmenu a{
    color: #333 !important;
  }
}

.footer .copyright {
  padding-top: 25px;
  padding-bottom: 25px;
  border-top: 1px solid
    color-mix(in srgb, var(--default-color), transparent 90%);
}

.footer .copyright p {
  margin-bottom: 0;
  color: var(--default-color-1);
}

.footer .copyright p a {
  color: var(--default-color-1);
  text-decoration: none;
}

.footer .credits {
  margin-top: 8px;
  font-size: 13px;
  color: var(--default-color-1);
}
.footer .credits a {
  color: var(--default-color-1);
  text-decoration: none;
}

@media (max-width: 767px) {
  .footer .copyright {
    display: flex;
    flex-direction: column;
  }
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  inset: 0;
  z-index: 999999;
  overflow: hidden;
  background: var(--background-color);
  transition: all 0.6s ease-out;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #ffffff;
  border-color: var(--accent-color) transparent var(--accent-color) transparent;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: animate-preloader 1.5s linear infinite;
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Scroll Top Button
--------------------------------------------------------------*/
.scroll-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
  background-color: var(--accent-color);
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.scroll-top i {
  font-size: 24px;
  color: var(--contrast-color);
  line-height: 0;
}

.scroll-top:hover {
  background-color: color-mix(in srgb, var(--accent-color), transparent 20%);
  color: var(--contrast-color);
}

.scroll-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Global Page Titles & Breadcrumbs
--------------------------------------------------------------*/
.page-title {
  color: var(--default-color);
  background-color: var(--background-color);
  position: relative;
}

.page-title .heading {
  padding: 80px 0;
  border-top: 1px solid color-mix(in srgb, var(--accent-color), transparent 80%);
}

.page-title .heading h1 {
  font-size: 38px;
  font-weight: 700;
}

.page-title nav {
  background-color: color-mix(in srgb, var(--accent-color), transparent 94%);
  padding: 20px 0;
}

.page-title nav ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.page-title nav ol li + li {
  padding-left: 10px;
}

.page-title nav ol li + li::before {
  content: "/";
  display: inline-block;
  padding-right: 10px;
  color: color-mix(in srgb, var(--default-color), transparent 70%);
}

.press-category-heading {
  font-size: 2rem;
  color: #333 !important;
  font-weight: 600;
}

/*--------------------------------------------------------------
# Global Sections
--------------------------------------------------------------*/
section,
.section {
  color: var(--default-color);
  /* background-color: var(--background-color); */
  padding: 60px 0;
  scroll-margin-top: 72px;
  overflow: clip;
}

@media (max-width: 1199px) {
  section,
  .section {
    scroll-margin-top: 60px;
  }
}

/*--------------------------------------------------------------
# Global Section Titles
--------------------------------------------------------------*/
.section-title {
  text-align: center;
  padding-bottom: 60px;
  position: relative;
}

.section-title h2 {
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.section-title h2:before {
  content: "";
  position: absolute;
  display: block;
  width: 160px;
  height: 1px;
  background: color-mix(in srgb, var(--default-color), transparent 60%);
  left: 0;
  right: 0;
  bottom: 1px;
  margin: auto;
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 60px;
  height: 3px;
  background: var(--accent-color);
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero {
  width: 100%;
  min-height: calc(100vh - 112px);
  padding: 80px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.hero img {
  position: absolute;
  inset: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.hero .container {
  z-index: 3;
}

.hero .welcome h2 {
  margin: 0;
  font-size: 48px;
  font-weight: 700;
  color: var(--heading-color-2) !important;
}

.hero .welcome p {
  font-size: 24px;
  margin: 0;
}

.hero .content {
  margin-top: 40px;
}

.hero .content .why-box {
  color: var(--contrast-color);
  background: var(--accent-color);
  padding: 30px;
  border-radius: 4px;
}

.hero .content .why-box h3 {
  color: var(--contrast-color);
  font-weight: 700;
  font-size: 34px;
  margin-bottom: 30px;
}

.hero .content .why-box p {
  margin-bottom: 30px;
}

.hero .content .why-box .more-btn {
  color: var(--contrast-color);
  background: color-mix(in srgb, var(--contrast-color), transparent 80%);
  display: inline-block;
  padding: 6px 30px 8px 30px;
  border-radius: 50px;
  transition: all ease-in-out 0.4s;
}

.hero .content .why-box .more-btn i {
  font-size: 14px;
}

.hero .content .why-box .more-btn:hover {
  background: var(--surface-color);
  color: var(--accent-color);
}

.hero .content .icon-box {
  text-align: center;
  border-radius: 10px;
  background: color-mix(in srgb, var(--surface-color), transparent 20%);
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  padding: 40px 30px;
  width: 100%;
}

.hero .content .icon-box i {
  font-size: 40px;
  color: var(--accent-color);
}

.hero .content .icon-box h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 10px 0 20px 0;
  color: var(--heading-color-2) !important;
}

.hero .content .icon-box p {
  font-size: 15px;
  color: color-mix(in srgb, var(--default-color), transparent 30%);
}

/*--------------------------------------------------------------
# About Section
--------------------------------------------------------------*/
.about .content h3 {
  font-size: 2rem;
  font-weight: 700;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  display: flex;
  align-items: flex-start;
  margin-top: 34px;
}

.about .content ul i {
  flex-shrink: 0;
  font-size: 40px;
  color: var(--accent-color);
  margin-right: 20px;
}

.about .content ul h5 {
  font-size: 17px;
  font-weight: 700;
  color: var(--heading-color-2) !important;
}

.about .content ul p {
  font-size: 14px;
}

.about .content p:last-child {
  margin-bottom: 0;
}

.about .pulsating-play-btn {
  position: absolute;
  left: calc(50% - 47px);
  top: calc(50% - 47px);
}

@media (min-width: 992px) {
  .about-li-min {
    margin-top: 0px !important;
  }
}

/*--------------------------------------------------------------
# Stats Section
--------------------------------------------------------------*/
.stats i {
  color: var(--accent-color);
  /* background-color: var(--accent-color); */
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
  width: 54px;
  height: 54px;
  font-size: 24px;
  border-radius: 50px;
  /* border: 2px solid var(--background-color); */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.stats .stats-item {
  background-color: var(--surface-color);
  margin-top: -27px;
  padding: 30px 30px 25px 30px;
  width: 100%;
  position: relative;
  text-align: center;
  box-shadow: 0px 2px 35px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 0;
}

.stats .stats-item span {
  font-size: 32px;
  display: block;
  margin: 10px 0;
  font-weight: 700;
  color: var(--heading-color);
}

.stats .stats-item p {
  padding: 0;
  margin: 0;
  font-family: var(--heading-font);
  font-size: 16px;
}

/*--------------------------------------------------------------
# Services Section
--------------------------------------------------------------*/
.services .service-item {
  background-color: var(--surface-color);
  text-align: center;
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 85%);
  padding: 40px 20px;
  transition: all ease-in-out 0.3s;
  height: 100%;
}

.services .service-item .icon {
  margin: 0 auto;
  width: 30px !important;
  height: 30px !important;
  background: var(--accent-color);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transition: 0.3s;
  transform-style: preserve-3d;
}

.services .service-item .icon i {
  color: var(--contrast-color);
  font-size: 15px;
  transition: ease-in-out 0.3s;
}

.services .service-item .icon::before {
  position: absolute;
  content: "";
  left: -8px;
  top: -8px;
  height: 100%;
  width: 100%;
  background: color-mix(in srgb, var(--accent-color), transparent 80%);
  border-radius: 5px;
  transition: all 0.3s ease-out 0s;
  transform: translateZ(-1px);
}

.services .service-item h3 {
  font-weight: 700;
  margin: 10px 0 0px 0;
  font-size: 16px;
  color: var(--default-color);
}

.services .service-item p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.services .service-item:hover {
  background: var(--accent-color);
  border-color: var(--accent-color);
}

.services .service-item:hover .icon {
  background: var(--surface-color) !important;
}

.services .service-item:hover .icon i {
  color: var(--accent-color) !important;
}

.services .service-item:hover .icon::before {
  background: color-mix(
    in srgb,
    var(--background-color),
    transparent 70%
  ) !important;
}

.services .service-item:hover h3,
.services .service-item:hover p {
  color: var(--contrast-color) !important;
}

/*--------------------------------------------------------------
# Appointment Section
--------------------------------------------------------------*/
.appointment .php-email-form {
  width: 100%;
}

.appointment .php-email-form .form-group {
  padding-bottom: 8px;
}

.appointment .php-email-form input,
.appointment .php-email-form textarea,
.appointment .php-email-form select {
  color: var(--default-color);
  background-color: transparent;
  border-color: color-mix(in srgb, var(--default-color), transparent 80%);
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  padding: 10px !important;
}

.appointment .php-email-form input:focus,
.appointment .php-email-form textarea:focus,
.appointment .php-email-form select:focus {
  border-color: var(--accent-color);
}

.appointment .php-email-form input::placeholder,
.appointment .php-email-form textarea::placeholder,
.appointment .php-email-form select::placeholder {
  color: color-mix(in srgb, var(--default-color), transparent 70%);
}

.appointment .php-email-form input,
.appointment .php-email-form select {
  height: 44px;
}

.appointment .php-email-form textarea {
  padding: 10px 12px;
}

.appointment .php-email-form button[type="submit"] {
  background: var(--accent-color);
  border: 0;
  padding: 10px 35px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
}

.appointment .php-email-form button[type="submit"]:hover {
  background: color-mix(in srgb, var(--accent-color) 90%, white 15%);
}

/*--------------------------------------------------------------
# Departments Section
--------------------------------------------------------------*/
.departments {
  overflow: hidden;
}

.departments .nav-tabs {
  border: 0;
}

.departments .nav-link {
  border: 0;
  padding: 12px 15px 12px 0;
  transition: 0.3s;
  color: var(--default-color);
  border-radius: 0;
  border-right: 2px solid
    color-mix(in srgb, var(--default-color), transparent 90%);
  font-weight: 600;
  font-size: 15px;
}

.departments .nav-link:hover {
  color: var(--accent-color);
}

.departments .nav-link.active {
  color: var(--accent-color) !important;
  border: 0px;
  background-color: var(--background-color) !important;
}

.departments .tab-pane.active {
  animation: fadeIn 0.5s ease-out;
}

.departments .details h3 {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 20px;
}

.departments .details p {
  color: color-mix(in srgb, var(--default-color), transparent 20%);
}

.departments .details p:last-child {
  margin-bottom: 0;
}

@media (max-width: 992px) {
  .department_mb_info {
    margin-top: 20px;
  }

  .departments .nav-link {
    border: 0;
    padding: 15px;
  }

  .departments .nav-link.active {
    color: var(--contrast-color);
    background: var(--accent-color);
  }
}

/*--------------------------------------------------------------
# Doctors Section
--------------------------------------------------------------*/

.team-member {
  background-color: var(--surface-color);
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  position: relative;
  border-radius: 5px;
  transition: 0.5s;
  padding: 30px;
  height: 100%;
}

@media (max-width: 468px) {
  .team-member {
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
  }
}

.team-member .pic {
  overflow: hidden;
  width: 150px;
  border-radius: 50%;
  flex-shrink: 0;
}

.team-member .pic img {
  transition: ease-in-out 0.3s;
  cursor: pointer;
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  font-size: 30px;
  cursor: pointer;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  align-items: center;
  justify-content: center;
}

.modal img {
  max-width: 90%;
  max-height: 90%;
}

.team-member:hover {
  transform: translateY(-10px);
}

.member-info {
  padding-left: 30px !important;
}

@media (max-width: 468px) {
  .member-info {
    padding: 30px 0 0 0;
    text-align: center;
  }
}

.team-member h2 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 28px;
  color: var(--heading-color-2) !important;
}
.team-member span {
  display: block;
  font-size: 15px;
  padding-bottom: 10px;
  position: relative;
  font-weight: 500;
}
.team-member span::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 1px;
  background: color-mix(in srgb, var(--default-color), transparent 85%);
  bottom: 0;
  left: 0;
}

@media (max-width: 468px) {
  .team-member span::after {
    left: calc(50% - 25px);
  }
}

.team-member p {
  margin: 10px 0 0 0;
  font-size: 14px;
}

.team-member .social {
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
}

@media (max-width: 468px) {
  .team-member .social {
    justify-content: center;
  }
}

.team-member .social a {
  background: color-mix(in srgb, var(--default-color), transparent 94%);
  transition: ease-in-out 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 36px;
  height: 36px;
}
.team-member .social a i {
  color: color-mix(in srgb, var(--default-color), transparent 20%);
  font-size: 16px;
  margin: 0 2px;
}

.team-member .social a:hover {
  background: var(--accent-color);
}

.team-member .social a:hover i {
  color: var(--contrast-color);
}
.team-member .social a + a {
  margin-left: 8px;
}

/*--------------------------------------------------------------
# Faq Section
--------------------------------------------------------------*/
.faq .faq-container .faq-item {
  background-color: var(--surface-color);
  position: relative;
  padding: 20px;
  margin-bottom: 15px;
  border: 1px solid color-mix(in srgb, var(--accent-color), transparent 75%);
  border-radius: 5px;
  overflow: hidden;
}

.faq .faq-container .faq-item:last-child {
  margin-bottom: 0;
}

.faq .faq-container .faq-item h3 {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin: 0 30px 0 0;
  transition: 0.3s;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--heading-color-1) !important;
}

.faq .faq-container .faq-item h3 .num {
  color: var(--accent-color);
  padding-right: 5px;
}

.faq .faq-container .faq-item h3:hover {
  color: var(--accent-color);
}

.faq .faq-container .faq-item .faq-content {
  display: grid;
  grid-template-rows: 0fr;
  transition: 0.3s ease-in-out;
  visibility: hidden;
  opacity: 0;
}

.faq .faq-container .faq-item .faq-content p {
  margin-bottom: 0;
  overflow: hidden;
}

.faq .faq-container .faq-item .faq-toggle {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 16px;
  line-height: 0;
  transition: 0.3s;
  cursor: pointer;
}

.faq .faq-container .faq-item .faq-toggle:hover {
  color: var(--accent-color);
}

.faq .faq-container .faq-active {
  background-color: var(--accent-color);
  border-color: var(--accent-color);
  color: var(--contrast-color);
}

.faq .faq-container .faq-active h3,
.faq .faq-container .faq-active h3:hover {
  color: var(--contrast-color) !important;
}

.faq .faq-container .faq-active .faq-content {
  grid-template-rows: 1fr;
  visibility: visible;
  opacity: 1;
  padding-top: 10px;
}

.faq .faq-container .faq-active .faq-toggle {
  transform: rotate(90deg);
  color: var(--accent-color);
}

/*--------------------------------------------------------------
# Testimonials Section
--------------------------------------------------------------*/
.testimonials .info h3 {
  font-weight: 700;
  font-size: 32px;
}

.testimonials .swiper {
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.05);
  background-color: var(--surface-color);
}

.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  box-sizing: content-box;
  min-height: 200px;
  position: relative;
  margin: 30px;
}

.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  height: 90px;
  border-radius: 50px;
  border: 6px solid var(--background-color);
  margin-right: 10px;
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: var(--heading-color-2) !important;
}

.testimonials .testimonial-item h4 {
  color: color-mix(in srgb, var(--default-color), transparent 50%);
  font-size: 14px;
  margin: 0;
}

.testimonials .testimonial-item .stars {
  margin: 10px 0;
}

.testimonials .testimonial-item .stars i {
  color: #ffc107;
  margin: 0 1px;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: color-mix(in srgb, var(--accent-color), transparent 60%);
  font-size: 26px;
  line-height: 0;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
  transform: scale(-1, -1);
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 15px auto 15px auto;
}

.testimonials .swiper-wrapper {
  height: auto;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background-color: color-mix(in srgb, var(--default-color), transparent 85%);
  opacity: 1;
  border: none;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--accent-color);
}

@media (max-width: 767px) {
  .testimonials .testimonials-carousel,
  .testimonials .testimonials-slider {
    overflow: hidden;
  }

  .testimonials .testimonial-item {
    margin: 15px;
  }
}

/*--------------------------------------------------------------
# Contact Section
--------------------------------------------------------------*/
.contact .info-item + .info-item {
  margin-top: 40px;
}

.contact .info-item i {
  color: var(--contrast-color);
  background: var(--accent-color);
  font-size: 20px;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
  margin-right: 15px;
}

.contact .info-item h3 {
  padding: 0;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5px;
}

.contact .info-item p {
  padding: 0;
  margin-bottom: 0;
  font-size: 14px;
}

.contact .info-item p a {
  color: var(--default-color);
}

.contact .info-item p a:hover {
  color: var(--accent-color);
}

.contact .php-email-form {
  height: 100%;
}

.contact .php-email-form input[type="text"],
.contact .php-email-form input[type="email"],
.contact .php-email-form input[type="tel"],
.contact .php-email-form textarea {
  font-size: 14px;
  padding: 8px 10px !important;
  box-shadow: none;
  /* height: 40px !important; */
  border-radius: 0;
  color: var(--default-color);
  background-color: color-mix(
    in srgb,
    var(--background-color),
    transparent 50%
  );
  border-color: color-mix(in srgb, var(--default-color), transparent 80%);
}

.contact .php-email-form input[type="text"]:focus,
.contact .php-email-form input[type="email"]:focus,
.contact .php-email-form input[type="tel"]:focus,
.contact .php-email-form textarea:focus {
  border-color: var(--accent-color);
}

.contact .php-email-form input[type="text"]::placeholder,
.contact .php-email-form input[type="email"]::placeholder,
.contact
  .php-email-form
  input[type="tel"]::placeholder
  .contact
  .php-email-form
  textarea::placeholder {
  color: color-mix(in srgb, var(--default-color), transparent 70%);
}

.contact .php-email-form button[type="submit"] {
  color: var(--contrast-color);
  background: var(--accent-color);
  border: 0;
  padding: 10px 36px;
  transition: 0.4s;
  border-radius: 50px;
}

.contact .php-email-form button[type="submit"]:hover {
  background: color-mix(in srgb, var(--accent-color), transparent 20%);
}

/*--------------------------------------------------------------
# Banner Section Section
--------------------------------------------------------------*/

.container_2 {
  position: relative;
  text-align: center;
  color: white;
  height: 600px;
}

.centered_1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 500px) {
  .centered_1 {
    width: 95%;
  }
  .container_2 {
    height: 350px;
  }
}

.centered_1 h1 {
  color: var(--background-color) !important;
  font-weight: bold;
}

.centered_1 h6 {
  color: var(--background-color) !important;
  margin-top: 30px;
  margin-bottom: 40px;
}

.container_2 button {
  background-color: var(--accent-color);
  color: var(--contrast-color);
  font-size: 18px;
  border-radius: 50px;
  border: 2px solid var(--accent-color);
  padding: 10px 25px;
  font-weight: bold;
}

.container_2 button:hover {
  background-color: transparent;
  border: 2px solid var(--accent-color);
  color: var(--accent-color);
  font-weight: bold;
}

/*--------------------------------------------------------------
# Pricing  Section
--------------------------------------------------------------*/

@media (max-width: 992px) {
  .pricing_column {
    margin-bottom: 20px;
  }
}
.pricing_column {
  border: 1px solid var(--heading-color-1);
  /* margin: 10px; */
  padding: 20px;
}

.price_description {
  height: 45px;
}

.pricing_column button {
  background-color: var(--accent-color);
  color: var(--contrast-color);
  border: 0px;
  padding: 8px 15px;
}

.pricing_column button:hover {
  background-color: transparent;
  color: var(--accent-color);
  border: 2px solid var(--accent-color);
  padding: 8px 15px;
}

.pricing_column h2 {
  margin-top: 40px;
  margin-bottom: 0px;
}

.pricing_column h6 {
  font-weight: bold;
  margin-top: 30px;
  color: var(--heading-color-2);
}

.pricing_column ul {
  margin-top: 8px;
  padding-left: 22px !important;
}

.text11 {
  color: var(--text-color);
  font-size: 11px;
}

.news-title {
  color: var(--text-color);
  font-size: var(--font-size);
  margin-bottom: 4px;
  line-height: 1;
}

.news-status-btn {
  text-decoration: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.custom-link {
  color: #333;
  font-weight: bold;
  cursor: pointer;
}

.custom-link:hover {
  color: #007bff;
}

.container {
  width: 100%;
  /* max-width: 1920px !important; */
  padding-right: 0.2rem !important;
  padding-left: 0.2rem !important;
  margin-right: auto;
  margin-left: auto;
}

.min-vh-100 {
  min-height: 100vh;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header {
  padding: 0px !important;
}

.header-navbar {
  padding-left: 2rem;
}

.nav-links .nav-link {
  margin-right: 1rem;
}

.nav-link:hover {
  border-radius: 0.5rem;
  background-color: white;
}

.sort-icon {
  font-size: 1em;
  color: gray;
}

.nav-link.custom {
  margin-right: 1rem;
}

.nav-link.custom:hover {
  background-color: transparent;
  color: #6329cb !important;
}

.nav-dropdown-custom > .nav-link {
  margin-right: 1rem;
}

.nav-dropdown-custom > .nav-link:hover {
  background-color: transparent;
  color: #6329cb !important;
}

.navbar-collapse.show,
.collapsing {
  position: absolute;
  top: 4rem;
  right: 0;
  margin-right: 0.5rem;
  border-radius: 1rem;
  z-index: 1000;
  background-color: white;
  box-shadow: 0 4px 8px rgba(255, 255, 0, 0.1);
  padding: 10px;
}

.handbugger.collapsed {
  position: relative;
  margin-right: 0.5rem;
  right: 0;
  border: none !important;
  background: transparent;
  outline: none !important;
  z-index: 1;
  cursor: pointer;
  display: block;
}

.handbugger.active {
  background-color: var(--accent-color);
}

.breadCrumb-custom {
  font-size: 0.8rem;
}

.breadCrumb-custom a {
  text-decoration: none;
  color: inherit;
}

.App {
  text-align: center;
  font-family: "Poppins", sans-serif !important;
}

.App-header {
  background-color: #e9ecef;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  animation: App-logo-spin infinite 20s linear;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-link {
  color: #61dafb;
}

.status-dropdown {
  /* appearance: none;
  background-color: transparent;
  border: none; */
  cursor: pointer;
  text-align: start;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 10px 15px;
  width: 30vw;
  /* left: 0%; */
  top: 100%;
}

.dropdownItem {
  padding: 4px 0px;
}

.dropdownItem:hover {
  background-color: #d32f2f;
}
/* .dropdownItem:hover a{
color: #fff;
}

.dropdownItem a{
color: #000  !important;
} */

@media (max-width: 969px) {
  .dropdown-content {
    margin-left: 20px;
    width: 90%;
    padding: 0px;
  }
  /* .dropdown-item {
    padding: 0px !important;
  }
  .dropdown-item a {
    padding-left: 12px;
  } */
}
@media (min-width: 969px) {
  /* .dropdown-item {
    padding: 10px !important;
    cursor: pointer;
  } */
}

/* .dropdown-item:hover {
  background-color: #f1f1f1;
} */

.text-capitalize {
  text-transform: capitalize;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
}

.approve-btn {
  background-color: #32cd32;
  color: #ffffff;
  border: none;
  padding: 0.3rem 0.6rem;
  border-radius: 0.3rem;
  cursor: pointer;
  font-size: 0.7rem;
  transition: background 0.3s ease;
  width: 4rem;
}

.approve-btn:hover {
  background-color: #11c711;
}

.reject-btn {
  background-color: #fb7a7d;
  color: white;
  border: none;
  padding: 0.3rem 0.6rem;
  border-radius: 0.3rem;
  cursor: pointer;
  font-size: 0.7rem;
  transition: background 0.3s ease;
  width: 4rem;
}

.reject-btn:hover {
  background-color: #f8262a;
}

/* Desktop 1200 - up */
@media (min-width: 1200px) {
  .container {
    max-width: 1280px;
  }
}

.desktop-only {
  display: none;
}

/* Mobile-only section */
.mobile-only {
  display: none;
}

/* Media queries */
@media (min-width: 768px) {
  .desktop-only {
    display: block;
  }
}

@media (max-width: 767px) {
  .mobile-only {
    display: block;
  }
}
.container_image {
  position: relative;
  text-align: center;
  color: white;
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  font-weight: 700;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .centered {
    font-size: 20px;
  }
}
.edit-guide-para {
  margin-bottom: 40px;
  margin-top: 10px;
}
.guidelines-heading {
  font-size: 20px;
  font-weight: 600;
}
.inner-page-body ul li {
  margin-bottom: 2px;
}
.inner-page-body .guidelines-main-li {
  margin-bottom: 40px;
}
.inner-page-body .guidelines-main-li::marker {
  font-size: 22px;
  font-weight: 600;
}
.inner-page-body ul,
.inner-page-body ol {
  padding-left: 20px !important;
}
@media (max-width: 767px) {
  .inner-page-body .guidelines-main-li {
    margin-bottom: 25px !important;
  }
  .edit-guide-para {
    margin-bottom: 25px;
    margin-top: 10px;
  }
}

.compare-price-head {
  border: 1px solid #868686;
  padding: 15px;
  /* min-height: 80px;*/
  display: flex;
  align-items: center;
  /* justify-content: center; */
  text-align: left;
  background-color: #e4e2e2;
}

.compare-price-head p {
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 600;
  /* white-space: nowrap; */
}

.compare-price-div p {
  font-size: 16px;
  margin-bottom: 0px;
  border: 1px solid #868686;
  border-top: 0px;
  padding: 15px;
  text-align: left;
  white-space: nowrap;
}

.comparison-pricing-section .col-3 {
  padding: 0px !important;
}

.bdr-rgt {
  border-right: 0px !important;
}

.plan-heading {
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 2.81rem;
}

.planFeature-heading{
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.81rem;
}

.plan-price {
  font-size: 1.25rem;
  font-weight: 600;
  margin-top: 70px;
}
.get-started-btn {
  background-color: #df1529;
  color: white;
  border: 0px;
  padding: 10px 20px;
  border-radius: 3px;
  font-weight: 600;
}
.view-details-btn {
  color: #0d6efd;
  text-align: start;
  padding-left: 0.1rem;
}
.price-plans-categories {
  margin-top: 70px;
}
@media (min-width: 767px) {
  .price-plan-head {
    height: 330px;
  }
}
.price-plan-div {
  padding: 20px 15px;
}
.price-plan-div:hover {
  background-color: #d7d7d7;
}
.plan-para {
  font-size: 14px;
}
.pricing-new-section {
  background-color: #e9e9e9;
}

/* Dashboard  CSS============= */

.dashboard_body_1{
    /* max-width:600px;
    margin: auto; */
    font-family: 'Poppins';
    /* margin-top: 90px; */
}
.div_1{
    padding: 10px 15px;
    border-radius: 10px;
    margin: 0px 0px 20px 0px;
}
.dashboard_body_1 .icon{
    border-radius: 50%;
    height: 35px;
    width: 35px;
    margin-bottom: 15px;
}
.dashboard_body_1 .icon i{
    color: #ffff;
    padding: 9px 0px 0px 11px;
}
.div_number{
    font-size: 24px;
    margin-bottom: 0px;
    font-weight: 600;
}
.div_name{
    margin-bottom: 0px;
}
.dashboard_body_1 .pd_dash{
    padding: 10px;
}
@media (max-width:969px) {
    .div_number{
        font-size: 20px;
    }
    .div_name{
        font-size: 14px;
    }
}





.bottom-menu {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-around;
}
.bottom-menu i{
    font-size: 14px;
}
.menu-item {
    text-align: center;
    flex: 1;
    padding: 5px;
    color: #333;
}
.menu-item.active {
    color: #007bff;
}
.menu-item p{
    margin-bottom: 0px;
    font-size: 14px;
}
.dashboard_body_1 .tab-content {
    padding-bottom: 60px;
}
.menu-item p{
    margin-bottom: 0px;
}
.notification_div{
    /* border:1px solid #000; */
    box-shadow: 0px 0px 10px 0px #c0c0c0;
    margin-bottom: 20px;
    border-radius: 8px;
    padding: 10px;
    display: flex;
}
.notification_name{
    margin-bottom: 5px;
    font-size: 15px;
}
.notification_date{
    font-size: 11px;
    margin-bottom: 0px;
}
.notification_div img{
    border-radius: 50%;
    margin-right: 15px;
    height: 100%;
}
@media (min-width:767px) {
    .bottom-menu{
        display: none;
    }
    .notification_div{
        padding: 15px !important;
    }
}


.dashboard-navbar .navbar{
    z-index: 1 !important;
}
.header-top{
    padding: 10px;
    position: fixed;
    height: 70px;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    background-color: #fff;
    box-shadow: 0px 0px 12px 2px #c5c5c5;
}
.left-menu {
    /* position: sticky;
    left: 0;
    width: 200px;
    background-color: #fff;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px; */
    height: 310px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    padding-top: 1rem;
    position: -webkit-sticky !important;
    position: sticky;
    top: 0
}
.left-menu::-webkit-scrollbar {
    display: none;
  }

.left-menu .menu-item {
    width: 100%;
    text-align: left;
    padding: 12px 20px;
    cursor: pointer;
    color: #333;
    font-size: 16px;
    display: flex;
    align-items: center;
}

.left-menu .menu-item i {
    margin-right: 10px;
}

.left-menu .menu-item.active {
    color: #007bff;
    font-weight: bold;
}

@media (min-width: 992px) {
    .left-menu {
        /* display: flex; */
        width: 20%;
        /* height: 300px; */
    }
    .right-content{
        width: 75% !important;
    }
    .bottom-menu {
        display: none;
    }
    .dashboard_body_1 {
        /* margin-left: 220px;  */
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
    }
}


body {
    font-family: "Lato", sans-serif;
}

.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1050;
    top: 0;
    left: 0;
    background-color: #ffffff;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 30px;
}

.sidenav a {
    padding: 10px 8px 10px 20px;
    text-decoration: none;
    font-size: 18px;
    color: #333;
    display: block;
    transition: 0.3s;
}

.sidenav a:hover {
    color: #f1f1f1;
    background-color: #6d6b6b;
}

.sidenav .closebtn {
    position: absolute;
    top: -14px;
    right: 8px;
    font-size: 32px;
    margin-left: 50px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out;
    z-index: 1040;
}

.overlay.show {
    opacity: 1;
    visibility: visible;
}

.navbar-header-dashboard{
    padding:5px 0px ;
    box-shadow: 0px 0px 12px 2px #e1e1e1;
}
   
.navbar-toggler {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: transparent;
    border: none;
    cursor: pointer;
    position: relative;
    z-index: 1100;
}

.toggler-icon {
    width: 30px;
    height: 3px;
    background-color: black;
    margin: 5px 0;
    transition: all 0.3s ease-in-out;
}

.navbar-toggler.open .toggler-icon {
    display: none ;
}

.navbar-toggler.open::before {
    content: "\00d7";
    font-size: 30px;
    color: black;
    position: absolute;
}


@media (min-width: 992px) {
    .navbar-expand-lg .navbar-toggler {
        display: none !important;
    }
}

@media (min-width:992px) {
    .mobile-only{
        display: none;
    }
}
@media (max-width:992px) {
    .desktop-only{
        display: none;
    }
}
.home-page-main-heading{
    font-size: 30px;
    padding: 0px 10px;
    margin-bottom: 5px;
    margin-top: 20px;
}
.menu-button{
    border: 2px solid;
    /* width: 200px; */
    padding: 10px;
}
.menu-button_div{
    padding: 12px 20px;
    margin-left: 55px;
    margin-top: 20px;
}
.update-profile{
    padding: 10px;
}
.update-profile-h5-1{
    margin-bottom: 20px;
    margin-top: 50px;
    font-size: 19px;
}
.update-profile-h5{
    margin-bottom: 30px;
    font-size: 19px;
}
.update-profile img{
    border-radius: 50%;
}
.change-pic-btn{
    background-color: #4ea2fc;
    color: #fff;
    border: 0px;
    padding: 6px 12px;
    border-radius: 7px;
    font-size: 14px;
    margin-left: 20px;
}
.profile-form-div{
    margin-bottom: 20px;
}
.profile-form-div input{
    width: 350px;
    padding: 5px;
    margin-top: 5px;
    border-radius: 5px;
    border: 1px solid #666;
    font-size: 14px;
}
.profile-form-info-div{
    margin-bottom: 20px;
}
.profile-form-info-div input, .profile-form-info-div textarea{
    width: 100%;
    padding: 8px 8px;
    margin-top: 5px;
    border-radius: 5px;
    border: 1px solid #666;
    font-size: 14px;
}
.about-row-profile{
    width: 80%;
}