.card {
  margin-bottom: 1.8rem;
}

.noGutters {
  height: 100%;
}
.cardBody {
  padding: 0 0.5rem;
}

.pressTitle {
  font-size: 20px !important;
  font-weight: 600;
  margin-bottom: 0px !important;
}
.pressTitle:hover {
  color: #d32f2f;
  /* text-decoration: underline; */
}

.pressTimeAndCategory {
  font-size: 12px !important;
  /* text-transform: capitalize; */
}

.pressContainer {
  padding-top: 0 !important;
}
.pressReleaseContainer {
  display: flex;
}

positionRelative {
  display: flex;
  align-items: stretch; 
  padding: 0 0 0 0.65rem !important;
}

.textCenter {
  text-align: center;
}

.bookmarkIcon {
  border-radius: 50%;
  background-color: white;
  color: inherit;
  position: absolute;
  right: 4px;
  top: 4px;
  z-index: 1;
  height: 1.8rem;
  width: 1.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}


.cardImg {
  width: 100%; 
  overflow-y: hidden;
  object-fit:cover; 
  border-radius: 0.25rem;
}

.newDateTime {
  text-transform: capitalize;
}

.pressSummary {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-size: 1rem;
}

@media (max-width: 576px) {
  .pressSummary {
    font-size: 0.7rem;
  }
  .pressContainer {
    padding: 0;
  }
  .bookmarkIcon {
    right: 1rem;
    top: 0.45;
  }
}

.bottomSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e9ecef;
  padding-top: 0.5rem;
}

.authorInfo {
  display: flex;
  align-items: center;
}

.authorImg {
  margin-right: 0.5rem;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
}

.iconSection {
  display: flex;
  align-items: center;
}

.iconWrapper {
  margin-right: 1rem;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  color: #555;
}
.viewIcon {
  width: 1.8rem;
  height: 1rem;
  fill: #555;
  /* margin-bottom: 0.3rem; */
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.likeIcon {
  cursor: pointer;
  transition: transform 0.1s ease, fill 0.1s ease;
  fill: #555;
  margin-right: 0.3rem !important;
}

.liked {
  fill: #d32f2f !important;
  animation: pulse 0.3s;
}

.notLiked {
  fill: #555;
}

@media (max-width: 720px) {
  .cardImg {
    max-height: 15rem;
  }

  .bottomSection {
    align-items: flex-start;
  
  }

  .iconSection {
 
    justify-content: space-between;
    width: 100%;
  }

  .iconWrapper {
    margin-right: 0.5rem;
  }
}

@media (max-width: 575.98px) {
  .cardBody {
    padding: 0.5rem;
  }

  .pressSummary {
    font-size: 0.85rem;
  }
}

.macard {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border: 0;
  border-radius: 8px;
}

.matextCenter {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mabookmarkIconWrapper {
  border-radius: 50%;
  background-color: white;
  color: inherit;
  position: absolute;
  right: 4px;
  top: 4px;
  z-index: 1;
  height: 1.8rem;
  width: 1.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mabookmarkIcon {
  color: goldenrod;
  padding-right: 0;
  padding-top: 2px;
}

.mabookmarkBorderIcon {
  padding-right: 0;
  padding-top: 2px;
}

.manewsSummary {
  margin-top: 0.5rem;
  font-size: 0.8rem;
}

.mabottomSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 0.6px solid #e9ecef;
  padding-top: 0.2rem;
}

.maauthorInfo {
  display: flex;
  align-items: center;
}

.maauthorImg {
  margin-right: 0.5rem;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  object-fit: cover;
}

.maiconSection {
  display: flex;
}

.maiconWrapper {
  margin-right: 1rem;
}

.maliked {
  fill: #6329cb;
  animation: pulse 0.3s;
}

.manotLiked {
  fill: black;
}

/*Market-Reports*/

.acustomCard {
  /* margin-bottom: 1.5rem; */
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.aresponsiveImage {
  min-height: 15rem;
  max-height: 15rem;
  object-fit: cover;
  width: 100%;
}

/*Market-Reports*/

.mrCard {
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  margin: 0.5rem 0;
  min-height: 18rem;
}
.mrCard:hover {
  box-shadow: 0px 4px 12px rgba(255, 255, 255, 0.5);
}

.mrcategoryBadge {
  background: linear-gradient(
    90deg,
    rgba(30, 48, 243, 1) 0%,
    rgba(226, 30, 128, 1) 100%
  );
  font-size: 0.75rem;
  padding: 0.2rem 0.3rem 0.5rem 0.3rem !important;
  border-radius: 20px;
}

.mrcardTitle {
  font-size: 1rem;
  font-weight: bold;
  margin-top: 10px;
}

.mrdescription {
  font-size: 0.8rem;
  color: #374151;
  margin-top: 10px;
  margin-bottom: 0px;
}

.mrinteractionRow {
  display: flex;
  align-content: end;
  padding-top: 1.5rem;
}

.mrinteractionItem {
  font-size: 0.9rem;
  display: flex;
  align-items: center;
}

.mrinteractionItem svg {
  margin-right: 5px;
}

.mrFeedContainer {
  margin: 0 !important;
  padding: 0 !important;
}

.mrFeedTitle {
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

@media (max-width: 700px) {
  .mrFeedTitle {
    font-size: 1.4rem;
    font-weight: 600;
    margin-left: 0;
  }
  .mabookmarkIconWrapper {
    right: 0;
    top: 0;
  }
  .mabookmarkIcon {
    color: goldenrod;
    padding-right: 0;
    padding-top: 2px;
  }
  .positionRelative {
    padding: 0 0.65rem !important;
  }
}

@keyframes bookmarkAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.bookmarkIcon {
  display: inline-block;
  transition: transform 0.3s ease-in-out;
}

.bookmarkIconAnimated .mabookmarkIconWrapperAnimated {
  animation: bookmarkAnimation 0.5s ease-in-out;
}

.actionButtonContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.actionButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.3rem;
  border-radius: 0.5rem;
  font-size: 0.75rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  border: none;
}

.actionButton::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: currentColor;
  opacity: 0.1;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease;
}

.actionButton:hover::before {
  transform: scaleX(1);
  transform-origin: left;
}

.upgradeButton {
  background-color: #dcfce7;
  color: #166534;
}

.editButton {
  background-color: #dbeafe;
  color: #1e40af;
}

.deleteButton {
  background-color: #fee2e2;
  color: #991b1b;
}

.actionButtonIcon {
  transition: transform 0.3s ease;
}

.actionButton:hover .actionButtonIcon {
  transform: scale(1.1);
}

.actionButtonText {
  transition: all 0.3s ease;
}

.actionButton:hover .actionButtonText {
  transform: translateX(2px);
}

/* Modified media query to keep buttons in one line on mobile */
@media (max-width: 640px) {
  .actionButtonContainer {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0.25rem;
    width: 100%;
  }

  .actionButton {
    padding: 0.25rem 0.5rem;
    font-size: 0.7rem;
    flex: 1;
    min-width: 0;
    justify-content: center;
  }
}

/* For extremely small screens */
@media (max-width: 350px) {
  .actionButtonText {
    font-size: 0.65rem;
  }
  
  .actionButton {
    padding: 0.2rem 0.35rem;
  }
}